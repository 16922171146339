.loading-container {
  position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  min-width: 100%;
  min-height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-container .loading-container__overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  width: 100%;
  height: 100%;

  background-color: #ffffff;
  opacity: 0.5;
}
